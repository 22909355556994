import * as React from "react"

type SlowLoading = {
	isLoaded: boolean
	isSlowToLoad: boolean
	onLoaded: () => void
}

export default function useSlowLoading(slowTimeout = 100): SlowLoading {
	const [isLoaded, setIsLoaded] = React.useState(false)
	const [isSlowToLoad, setIsSlowToLoad] = React.useState(false)
	const slowTimeoutRef = React.useRef(slowTimeout)

	React.useEffect(() => {
		if (isLoaded) return

		const timeoutId = setTimeout(() => {
			setIsSlowToLoad(true)
		}, slowTimeoutRef.current)
		return () => {
			clearTimeout(timeoutId)
		}
	}, [isLoaded])

	function onLoaded() {
		setIsLoaded(true)
	}

	return { isLoaded, isSlowToLoad, onLoaded }
}
