"use client"

import classNames from "classnames"
import Image from "next/image"

import useSlowLoading from "@/lib/useSlowLoading"

import portraitImageSrc from "../public/images/me.jpg"
import * as styles from "./Portrait.css"

type AboutMeImageProps = { author: string }
export default function Portrait({ author }: AboutMeImageProps) {
	const { isLoaded, isSlowToLoad, onLoaded } = useSlowLoading()
	return (
		<div
			className={classNames(
				styles.portrait,
				isLoaded && styles.loadedPortrait,
				isSlowToLoad && styles.slowToLoadPortrait,
			)}
		>
			<Image
				className={styles.portraitImage}
				src={portraitImageSrc}
				// Fixme: This is hardcoded from the css...
				sizes={styles.portraitImageSizes}
				priority
				alt={`${author}'s outdated portrait`}
				onLoad={onLoaded}
				fill
			/>
		</div>
	)
}
