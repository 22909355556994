"use client"

import * as React from "react"

import * as style from "./MailButton.css"
import Button from "./system/Button"
import { Mail as MailIcon } from "./system/icons"
import { JsonValue } from "type-fest"

const STILL_LOADING_TIMEOUT = 500

const EMAIL_ENDPOINT = "/api/email"

export default function MailButton(): React.ReactElement {
	const [state, dispatch] = React.useReducer(reducer, { status: "IDLE" })

	let isFetching =
		state.status === "FETCHING" || state.status === "SLOWLY_FETCHING"

	React.useEffect(() => {
		if (!isFetching) return

		let isCanceled = false

		let timeOut = setTimeout(() => {
			dispatch({ type: "STILL_FETCHING" })
		}, STILL_LOADING_TIMEOUT)

		fetch(EMAIL_ENDPOINT)
			.then((response) => {
				if (!response.ok) throw new Error(response.statusText)
				return response.json() as Promise<JsonValue>
			})
			.then((data) => {
				if (isCanceled) return
				if (!isEmailResponseData(data)) {
					throw new Error("Invalid email response data")
				}
				dispatch({ type: "FETCHED", email: data })
			})
			.catch((error) => {
				if (isCanceled) return
				dispatch({ type: "ERROR", error })
			})
			.finally(() => {
				clearTimeout(timeOut)
			})

		return () => {
			isCanceled = true
			clearTimeout(timeOut)
		}
	}, [isFetching])

	function fetchEmail(evt: React.MouseEvent) {
		evt.stopPropagation()
		evt.preventDefault()
		dispatch({ type: "FETCHING" })
	}

	switch (state.status) {
		case "FETCHING":
		case "IDLE":
			return (
				<>
					<noscript>JavaScript required to show email</noscript>
					<Button
						look="text"
						title="Click to show email"
						onClick={fetchEmail}
						disabled={state.status === "FETCHING"}
						icon={<MailIcon />}
					>
						show email
					</Button>
				</>
			)
		case "SLOWLY_FETCHING":
			return (
				<Button look="text" disabled={true} icon={<MailIcon />}>
					loading email
					<span className={style.loaderDot}>.</span>
					<span className={style.loaderDot}>.</span>
					<span className={style.loaderDot}>.</span>
				</Button>
			)
		case "FETCHED":
			return (
				<Button
					look="text"
					href={`mailto:${state.email.prefix}@${state.email.domain.join(".")}`}
					icon={<MailIcon />}
				>
					<span className={style.emailPrefix}>{state.email.prefix}</span>
					{state.email.domain.map((d, i) => (
						// biome-ignore lint/suspicious/noArrayIndexKey: this is fine here.
						<span key={i} className={style.emailDomain}>
							{d}
						</span>
					))}
				</Button>
			)
		default:
			return <>Cannot load email...</>
	}
}

type State =
	| { status: "IDLE" | "FETCHING" | "SLOWLY_FETCHING" }
	| { status: "FETCHED"; email: Email }
	| { status: "CRASHED"; error: Error }

type Action =
	| { type: "FETCHING" }
	| { type: "STILL_FETCHING" }
	| { type: "ERROR"; error: Error }
	| { type: "FETCHED"; email: Email }

type Email = { prefix: string; domain: string[] }

function reducer(state: State, action: Action): State {
	switch (action.type) {
		case "FETCHING":
			return { ...state, status: "FETCHING" }
		case "STILL_FETCHING":
			if (state.status === "FETCHING") {
				return { ...state, status: "SLOWLY_FETCHING" }
			}
			throw new Error(`${action.type} only valid when status is FETCHING`)
		case "ERROR":
			return { ...state, status: "CRASHED", error: action.error }
		case "FETCHED":
			return { ...state, status: "FETCHED", email: action.email }
	}
}

function isEmailResponseData(data: unknown): data is Email {
	return (
		typeof data === "object" &&
		data !== null &&
		"prefix" in data &&
		typeof data.prefix === "string" &&
		"domain" in data &&
		Array.isArray(data.domain) &&
		data.domain.every((d) => typeof d === "string")
	)
}
